import * as _state from './state';

const get = () => {
    return _state.get('notification/getNotifications');
}

const set = (message, type) => {
    let notifications = _state.get('notification/getNotifications');

    notifications.push({
        id:(Math.random().toString(36) + Date.now().toString(36)).substr(2),
        content: {
            type: type,
            message: message,
        },
    });

    _state.set('notification/setNotification', notifications);
}

const remove = (id) => {
    let notifications = _state.get('notification/getNotifications');
    notifications = notifications.filter(notification => notification.id !== id);
    _state.set('notification/setNotification', notifications);
}

export {
    get,
    set,
    remove
}