export default {
    namespaced: true,
    state: {
        transaction: false,
    },

    getters: {
        getTransaction: (state) => (state.transaction ? state.transaction : false),
    },

    mutations: {
        TRANSACTION(state, transaction) {
            state.transaction = transaction;
        },
    },

    actions: {
        setTransaction({ commit }, transaction) {
            commit('TRANSACTION', transaction);
        },
    },
};
