export default {
    namespaced: true,
    state: {
        location: false,
    },

    getters: {
        getLocation: (state) => (state.location ? state.location : false),
    },

    mutations: {
        LOCATION(state, location) {
            state.location = location;
        },
    },

    actions: {
        setLocation({ commit }, location) {
            commit('LOCATION', location);
        },
    },
};
