<template>
    <div>
        <div class="fixed w-full z-10 inset-0 overflow-y-auto ccss--modal-password-forgot" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="">
                        <div class="modal-close">
                            <a @click="close()" class="cursor-pointer text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </a>
                        </div>
                        <div class="p-8">
                            <div class="text-center sm:text-left">
                                <h2 class="text-center text-3xl font-extrabold">
                                    {{ $t('auth.passwordforgot.title') }}
                                </h2>
                            </div>
                        </div>

                        <div class="mt-1">
                            <div class="">
                                <div class="pb-8 px-4  sm:rounded-sm sm:px-10">
                                    <div class="flex mb-5 p-3 rounded-sm alert-success" v-show="valid">
                                        <div class="flex-grow">{{ $t('auth.passwordforgot.sendemail')}}</div>
                                    </div>
                                    <div class="flex mb-5 p-3 rounded-sm alert-danger" v-show="invalid">
                                        <div class="flex-grow">{{ invalid }}</div>
                                        <!-- <div class="flex-grow">{{ $t('auth.passwordforgot.emailnotfound') }}</div> -->
                                    </div>
                                    <form class="space-y-6" @submit.prevent="submit">
                                        <div>
                                        <label for="email" class="block text-sm font-medium text-gray-700">
                                        {{ $t('fields.email') }} *
                                        </label>
                                        <div class="mt-1">
                                            <input v-model="form.email" @focus="invalid = false" id="email" name="email" type="email" autocomplete="email" required class="lowercase appearance-none block w-full px-3 py-2 border rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                                        </div>
                                        </div>
                                        <div>
                                            <button type="submit" class="w-full flex justify-center py-3 px-4 btn btn-primary rounded-sm shadow-sm text-sm font-medium">
                                                {{ $t('auth.passwordforgot.button.submit') }}
                                            </button>
                                        </div>

                                        <div class="flex items-center justify-center">
                                            <div class="text-sm">
                                                <a @click="$emit('open-login');close()" class="cursor-pointer font-medium">
                                                    {{ $t('auth.passwordforgot.button.back') }}
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-password-reset ref="passwordreset" />
    </div>
</template>

<script>
import * as _api from '../lib/api'
import * as _applicationState from '../lib/application-state';

// components
import ModalPasswordReset from '../components/ModalPasswordReset'

export default {
    name: 'PasswordForget',
    components: {
        ModalPasswordReset
    },
    data() {
        return {
            show: false,
            invalid: false,
            valid: false,
            saving: false,
            form: {
                email: '',
            }
        }
    },
    methods: {

        open() {
            this.reset()
            this.show = true
        },

        close() {
            this.reset()
            this.show = false

            if (_applicationState.isAuthenticationRequired()) {
                this.$emit('open-login')
            }
        },

        reset() {
            this.saving = false;
            this.invalid = false;
            this.valid = false;
            this.form = {
                email: '',
            }
        },

        async submit () {
            if (this.saving === true) {
                return;
            }
            this.saving = true;

            const response = await _api.post('password/forget', this.form);

            if (response.result === 'failed') {
                this.invalid = response.message;
                this.saving = false;

                return;
            }

            this.$refs.passwordreset.open(this.form.email);

            this.close();
        }
    },
}
</script>
