export default {
    namespaced: true,
    state: {
        config: JSON.stringify({theme: {}, settings: {}, localization: 'nl'})
    },

    getters: {
        getConfig: state => state.config,
    },

    mutations: {
        CONFIG(state, config) {
            state.config = config;
        },
    },

    actions: {
        setConfig({ commit }, config) {
            commit('CONFIG', config);
        },
    }
}
