export default {
    namespaced: true,
    state: {
        applicationState: JSON.stringify({})
    },

    getters: {
        getApplicationState: state => state.applicationState,
    },

    mutations: {
        APPLICATIONSTATE(state, applicationState) {
            state.applicationState = applicationState;
        },
    },

    actions: {
        setApplicationState({ commit }, applicationState) {
            commit('APPLICATIONSTATE', applicationState);
        },
    }
}
