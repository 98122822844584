import { i18n } from '../main';

import * as _api from './api';
import * as _log from './log';
import * as _transaction from './transaction';
import * as _notification from './notification';
import * as _applicationState from '../lib/application-state';
import moment from 'moment';

// set the logger
const logger = _log.get('ELOCKERS');

const getEvents = async (data) => {
    logger.log('Get elocker events')();
    return _api.get('elockers/events', data);
};

const getAvailableLockerTypes = async () => {
    logger.log('Get available locker types')();

    const lockers = await _api.get('items', {
        elockers_active: true,
    });

    if (!lockers) {
        _notification.set('Failed to retrieve available lockers', 'error');
    }

    return lockers;
};

const reserve = async (type, event, location, area) => {
    const next = event.next;

    // let config = _state.get('config/getConfig');
    // todo
    // config.ticket_max_tickets
    if (_transaction.getTicketCounter('elockers') >= 2) {
        // todo: message to maximum tickets
        // _state.setField('status/getStatus', 'status/setStatus', 'ticket_max_tickets', true);

        return false;
    }
    // set loading
    _applicationState.setLoading(true);

    // reserve a locker through the api
    let tickets = [];

    let response = await _api.post('elockers/tickets/reserve', {
        quantity: 1,
        type: type,
        elockers_event_id: event.id,
        elockers_location_id: location.id,
        elockers_area_id: area.id,
        next,
    });

    if (response && response.result === 'success') {
        if (next === true) {
            tickets.push({
                lockernumber: null,
                ticket_id: null,
                batch_id: null,
                type: type,
                vendor: 'elockers',
                details: {
                    next,
                    event_id: event.id,
                    event_date: moment(event.date).format('DD-MM-YYYY'),
                    event_name: event.name,
                    location_id: location.id,
                    location_name: location.name,
                    area_id: area.id,
                    area_name: area.name,
                    cabinet_color: null,
                    type: type,
                },
            });
        } else {
            for (const ticket of response.data) {
                tickets.push({
                    lockernumber: ticket.lockernumber,
                    ticket_id: ticket.ticket_id,
                    batch_id: ticket.batch_id,
                    type: ticket.type,
                    vendor: 'elockers',
                    details: {
                        next,
                        event_id: ticket.event_id,
                        event_date: ticket.event_date,
                        event_name: ticket.event_name,
                        location_id: ticket.location_id,
                        location_name: ticket.location_name,
                        area_id: ticket.area_id,
                        area_name: ticket.area_name,
                        cabinet_color: ticket.cabinet_color,
                        type: ticket.type,
                    },
                });

                logger.log('ticket reserved', ticket)();
            }
        }
    } else {
        logger.log('ticket not reserved')();
        // _transaction.cancelExpireLockerTimer();

        if (response.message === 'unavailable') {
            _notification.set(i18n.global.t('lockers.notifications.unavailable'), 'error');
        } else {
            _notification.set(response.message ?? 'Failed to reserve ticket', 'error');
        }
        // _state.setField('status/getStatus', 'status/setStatus', 'ticket_unavailable_ticket', true);
        // status.ticket_unavailable_ticket = true;

        // _state.set('status/setStatus', status);
    }

    // set loading
    _applicationState.setLoading(false);

    return tickets.length > 0 ? tickets : false;
};

// TODO: implemtent legacy
const release = async (tickets) => {
    // set loading
    _applicationState.setLoading(true);

    let ticketsPayload = [];

    for (const ticket of tickets) {
        if (!ticket.details.next) {
            ticketsPayload.push({ ticket_id: ticket.ticket_id, batch_id: ticket.batch_id });
        }
    }

    if (ticketsPayload.length === 0) {
        _applicationState.setLoading(false);
        return true;
    }

    const response = await _api.post('elockers/tickets/release', {
        tickets: ticketsPayload,
    });

    if (response && response.result === 'success') {
        logger.log('tickets released', response)();
    } else {
        logger.log('tickets not released')();
    }

    // set loading
    _applicationState.setLoading(false);

    return response;
};

export { getEvents, getAvailableLockerTypes, reserve, release };
