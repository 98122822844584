<template>
    <div class="blur-background"></div>
</template>

<script>
export default {
    name: 'blur',
};
</script>

<style>

.blur-background {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    background: rgba(85,85,85, 0.75);
    z-index: 40;
}

.fixed-modal-wrapper {
    z-index: 50;
}

</style>