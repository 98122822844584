<template>
    <transition name="blur-background">
        <blur v-if="closeTablePopup">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-auto-close-table fixed-modal-wrapper" v-if="closeTablePopup">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div class="">
                        <div class="modal-close">
                            <button @click="cancelCloseTable()" class="modal-close" aria-label="Close">
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div class="pt-8 p-4">
                            <h2 class="text-center text-xl pr-8">{{ $t('table.autoclose.title') }}:</h2>
                            <h3 class="text-center py-3 text-xl">{{ this.closeTableTimer }}</h3>

                            <div class="flex gap-2">
                                <button @click="cancelCloseTable()" class="btn btn-confirm w-full">
                                    {{ $t('order.button.back') }}
                                </button>
                                <button @click="closeTable()" class="btn btn-close w-full">
                                    {{ $t('table.autoclose.close') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import * as _table from '../lib/table.js';
import Blur from "./Blur";

export default {
    components: {
      Blur,
    },
    data () {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            closeTablePopup: false,
            inactivityTimer: null,
            inactivityTimerDuration: 10 * 1000 * 60, // 10 minutes
            closeTableTimer: null,
        };
    },
    mounted () {
        if (this.tableAutoClose) {
            this.initAutoCloseTable();
        }
    },

    methods: {
        initAutoCloseTable () {
            this.events.forEach(function (event) {
                window.addEventListener(event, this.resetInactivityTimer);
            }, this);

            this.setInactivityTimer();
        },

        setInactivityTimer () {
            if (this.closeTablePopup === true) {
                return;
            }

            if (this.tableAutoClose === false) {
                return;
            }

            this.inactivityTimer = setTimeout(() => {
                this.setCloseTableTimer();
            }, this.inactivityTimerDuration);
        },

        resetInactivityTimer () {
            clearTimeout(this.inactivityTimer);
            this.setInactivityTimer();
        },

        setCloseTableTimer () {
            this.closeTablePopup = true;
            this.closeTableTimer = 10;
        },

        cancelCloseTable () {
            this.closeTablePopup = false;
            this.closeTableTimer = null;
            this.resetInactivityTimer();
        },

        closeTable () {
            this.closeTablePopup = false;
            _table.closeTable();
        },
    },
    computed: {
        tableAutoClose () {
            return _table.initAutoClose();
        },
        table () {
            return _table.get();
        },
    },
    watch: {
        tableAutoClose () {
            if (this.tableAutoClose) {
                this.initAutoCloseTable();
                return;
            }
        },
        closeTableTimer: {
            handler (value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.closeTableTimer--;
                    }, 1000);
                    return;
                }

                if (this.closeTablePopup) {
                    this.closeTable();
                }
            },
            immediate: true,
        },
    },
};
</script>
