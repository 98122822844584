export default {
    namespaced: true,
    state: {
        start_transaction_timestamp: false,
        transaction_timeout: false,
    },

    getters: {
        getStartTransactionTimestamp: (state) => state.start_transaction_timestamp,
        getTransactionTimeout: (state) => state.transaction_timeout,
    },

    mutations: {
        SET_START_TRANSACTION_TIMESTAMP(state, timestamp) {
            state.start_transaction_timestamp = timestamp;
        },
        SET_TRANSACTION_TIMEOUT(state, timeout) {
            state.transaction_timeout = timeout;
        },
        CLEAR_TRANSACTION_TIMEOUT(state) {
            clearTimeout(state.transaction_timeout);
            state.transaction_timeout = false;
            state.start_transaction_timestamp = false;
        },
    },

    actions: {
        setStartTransactionTimestamp({ commit }, timestamp) {
            commit('SET_START_TRANSACTION_TIMESTAMP', timestamp);
        },
        setTransactionTimeout({ commit }, timeout) {
            commit('SET_TRANSACTION_TIMEOUT', timeout);
        },
        clearTransactionTimeout({ commit }) {
            commit('CLEAR_TRANSACTION_TIMEOUT');
        },
    },
};
