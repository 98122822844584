<template>
    <div class="fixed z-50 inset-0 overflow-y-auto ccss--modal-terms-conditions" v-if="show">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div class="modal container px-5 inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full radius-anchor" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="h-[60vh]">
                    <div class="min-h-[60vh] sm:flex sm:items-start">
                        <div class="text-center sm:text-left">
                            <h3 class="mt-3 text-lg leading-6 font-bold text-2xl text-gray-900" id="modal-headline">
                                {{ this.title }}
                            </h3>
                            <div v-html="this.content" class="mt-2"></div>
                        </div>
                    </div>
                </div>
                <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span class="flex w-full rounded-sm shadow-sm sm:mr-3 sm:w-auto">
                        <button type="button" @click="close()" class="inline-flex justify-center w-full rounded-sm border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                            {{ $t('button.close') }}
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            content: '',
            show: false,
        }
    },
    methods: {
        open (title, content) {
            this.title = title;
            this.content = content;
            this.show = true;
        },
        close() {
            this.show = false;
        },
    },
}
</script>
