import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import applicationState from './application-state';
// import clientAccess from './client-access';
import config from './config';
import token from './token';
import lockers from './lockers';
import location from './location';
import table from './table';
import transaction from './transaction';
import user from './user';
import notification from './notification';
// import payment from './payment';
// import status from './status';
// import token from './token';

export default createStore({
    plugins: [createPersistedState()],
    modules: {
        applicationState,
        // clientAccess,
        config,
        lockers,
        location,
        token,
        table,
        transaction,
        user,
        notification,
    },
});
