<template>
    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-login fixed-modal-wrapper" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div>
                        <div class="modal-close">
                            <a @click="close()"
                               class="cursor-pointer text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                               aria-label="Close"
                            >
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div class="">
                            <div class="text-center sm:text-left py-8 px-4  sm:rounded-sm sm:px-10 pb-0">
                                <h2 class="text-center text-3xl font-extrabold">
                                    {{ $t('register.title') }}
                                </h2>
                                <p class="mt-2 text-center text-sm max-w">
                                    {{ $t('register.login.already') }}
                                    <a @click="$emit('open-login');close()" class="cursor-pointer font-medium">
                                        {{ $t('register.login.button') }}
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div class="">
                            <div class="">
                                <div class="pt-8 p-4  sm:rounded-sm ">

                                    <Form class="" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">

                                        <div class="flex mb-5 p-3 rounded-sm alert-danger" v-show="invalid">
                                            <div class="flex-grow">Invalid data</div>
                                        </div>
                                        <div class=" space-y-6 p-4 pb-8">

                                            <div class="mb-3">
                                                <label for="name" class="block text-sm font-medium"
                                                       :class="{'text-red-500': errors.name}"
                                                >{{ $t('fields.name') }} *</label>
                                                <div class="mt-1">
                                                    <Field type="text" v-model="form.name" name="name"
                                                           class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                                           :class="{'error': errors.name}"
                                                    />
                                                </div>
                                            </div>

                                            <div class="mb-3">
                                                <label for="email" class="block text-sm font-medium"
                                                       :class="{'text-red-500': errors.email}"
                                                >{{ $t('fields.email') }} *</label>
                                                <div class="mt-1">
                                                    <Field type="email" v-model="form.email" name="email"
                                                           class="lowercase py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                                           :class="{'error': errors.email}"
                                                    />
                                                </div>
                                            </div>

                                            <div class="mb-3">
                                                <label for="password" class="block text-sm font-medium"
                                                       :class="{'text-red-500': errors.password}"
                                                >{{ $t('fields.password') }} *</label>
                                                <div class="mt-1">
                                                    <Field type="password" v-model="form.password" name="password"
                                                           class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                                           :class="{'error': errors.phone_number}"
                                                    />
                                                </div>
                                            </div>

                                            <div class="mb-10">
                                                <label for="password_confirmation"
                                                       class="block text-sm font-medium"
                                                       :class="{'text-red-500': errors.password}"
                                                >{{ $t('fields.confirmpassword') }} *</label>
                                                <div class="mt-1">
                                                    <Field type="password" v-model="form.password_confirmation"
                                                           name="password_confirmation"
                                                           class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                                           :class="{'error': errors.phone_number}"
                                                    />
                                                </div>
                                            </div>

                                            <div class="mb-10">
                                                <label for="phone_number"
                                                       class="block text-sm font-medium text-gray-700"
                                                       :class="{'text-red-500': errors.phone_number}"
                                                >{{ $t('fields.phonenumber') }}</label>
                                                <div class="mt-1">
                                                    <Field type="text" v-model="form.phone_number" name="phone_number"
                                                           class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                                           :class="{'error': errors.phone_number}"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div>
                                            <button type="submit"
                                                    class="w-full flex justify-center py-3 px-4 btn btn-primary rounded-sm shadow-sm text-sm font-medium"
                                            >
                                                {{ $t('register.button') }}
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import {
    Field,
    Form,
} from 'vee-validate';
import * as yup from "yup";

// libs
import * as _auth from '../lib/auth';
import * as _applicationState from '../lib/application-state';

import Blur from "./Blur";

export default {
    name: 'Register',
    components: {
        Field,
        Form,
        Blur,
    },
    data () {
        const schema = yup.object().shape({
            name: yup.string().required().max(255),
            email: yup.string().required().email().max(255),
            phone_number: yup.string().max(255),
            password: yup.string().required().max(255),
            password_confirmation: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match'),
        });

        return {
            schema,
            show: false,
            saving: false,
            alert: {
                show: false,
                message: '',
                class: '',
            },
            form: {
                name: '',
                email: '',
                phone_number: '',
                password: '',
                password_confirmation: '',
            },
        };
    },
    methods: {
        mount () {
            this.error.email = true;
        },
        open () {
            this.reset();
            this.show = true;
        },

        close () {
            this.reset();
            this.show = false;

            if (_applicationState.isAuthenticationRequired()) {
                this.$emit('open-login');
            }
        },

        reset () {
            this.form = {
                name: '',
                email: '',
                phone_number: '',
                password: '',
                password_confirmation: '',
            };
        },


        async onSubmit (data) {
            this.saving = true;

            // todo: needs testing
            const response = await _auth.register(data);

            this.saving = false;

            if (response) {
                this.close();
            }

            // todo
            this.invalid = true;
        },
    },
};
</script>
