const formatSeconds = (seconds) => {
  return new Date(Math.round(seconds) * 1000).toISOString().substring(14, 19)
}

const setCssTheme = (theme) => {
  if (theme) {
      let style = document.createElement('link');
      style.type = 'text/css';
      style.rel = 'stylesheet';
      style.href = theme;
      document.head.appendChild(style);

      const cssnum = document.styleSheets.length;

      var styleTimeput = setInterval(function () {
          if (document.styleSheets.length > cssnum) {
              self.cssLoaded = true;
              clearInterval(styleTimeput);
          }
      }, 10);
  }
}

export { formatSeconds, setCssTheme }
