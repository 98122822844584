export default {
    namespaced: true,
    state: {
        table: false,
        auto_close: true,
    },

    getters: {
        getTable: state => state.table ? state.table : false,
        getAutoClose: state => state.auto_close ? state.auto_close : false,
    },

    mutations: {
        TABLE(state, table) {
            state.table = table;
        },
        AUTO_CLOSE(state, auto_close) {
            state.auto_close = auto_close;
        }
    },

    actions: {
        setTable({ commit }, table) {
            commit('TABLE', table);
        },
        setAutoClose({ commit }, auto_close) {
            commit('AUTO_CLOSE', auto_close);
        }
    }
}
