export default {
    namespaced: true,
    state: {
        notifications: JSON.stringify([]),
    },

    getters: {
        getNotifications: (state) => state.notifications,
    },

    mutations: {
        SET_NOTIFICATION(state, notifications) {
            state.notifications = notifications;
        },
    },

    actions: {
        setNotification({ commit }, notification){
            commit('SET_NOTIFICATION', notification);
        },
    }
}
