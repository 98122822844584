export default {
    namespaced: true,
    state: {
        user: JSON.stringify({authenticated: false})
    },

    getters: {
        getUser: state => state.user,
    },

    mutations: {
        USER(state, user) {
            state.user = user;
        },
    },

    actions: {
        setUser({ commit }, user) {
            commit('USER', user);
        },
    }
}
