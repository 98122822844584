<template>
    <div class="m-2">
        <div class="logo-wrapper">
            <div class="logo-inner">
                <div class="sjef-logo">
                    <img src="../assets/img/logos/sjef_logo-white.png" alt="">
                    <div class="snippet" data-title=".dot-pulse">
                        <div class="stage">
                            <div class="dot-pulse"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'status.booting',
};
</script>

