<template>
    <footer id="footer" v-if="transactionMethod !== 'elockers'">

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div v-if="location.id && $route.name !== 'deliverymethod'" class="sm:hidden pt-8">

                <div v-if="config.theme.show_opening_hours === true && has_set_opening_hours == true" class="mb-8">
                    <h3 class="text-sm font-semibold tracking-wider uppercase">
                        {{ $t('footer.openingtimes') }}
                    </h3>

                    <ul class="mt-2 space-y-1">
                        <li v-for="(times, day) in location.openingstimes" :key="day" class="flex text-base" :class="{'font-bold': today == day}">
                            <div class="w-1/2">{{ $t('openingtimes.' + day) }}:</div>
                            <div v-if="times" class="w-1/2">{{ times }}</div>
                            <div v-else class="w-1/2">Gesloten</div>
                        </li>
                    </ul>
                </div>

                <div class="">
                    <h3 class="text-sm font-semibold tracking-wider uppercase">
                        {{ $t('footer.contactdetails') }}
                    </h3>

                    <ul class="mt-2 space-y-1">
                        <li class="font-bold" v-if="location.name">{{ location.name }}</li>
                        <li v-if="location.streetname">{{ location.streetname }} {{ location.housenumber }}</li>
                        <li v-if="location.zipcode || location.city">{{ location.zipcode }}, {{ location.city }}</li>
                        <li class="text-base pt-2" v-if="location.phone_number">
                            <a :href="'tel:' + location.phone_number"
                                class="phone-link"
                            >
                                {{ location.phone_number }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="mt-4 py-4 items-center justify-center">
                <p class="mt-8 gap-2 flex text-base justify-center md:mt-0 md:order-1">
                    &copy; Powered by <a href="https://www.sjef.app/" target="_blank">sjef.app</a> - {{ appVersion }}
                </p>
                <div class="px-5 my-4 flex justify-center gap-5 text-gray-400">
                    <button v-if="config.legals['terms-of-use']" @click="this.$refs.popup.open($t('termsandconditions'), config.legals['terms-of-use'])">
                            {{ $t('modal.termsandcondition.button.open') }}
                    </button>
                    <button v-if="config.legals['privacy-policy']" @click="this.$refs.popup.open($t('privacypolicy'), config.legals['privacy-policy'])">
                            {{ $t('modal.privacypolicy.button.open') }}
                    </button>
                </div>
            </div>

            <!-- Display Social Media Icons -->
            <div align="center" class="pt-5 pb-10" v-if="location.social_media">
                <div class="social-icons">
                    <template v-for="(social_link, social_name) in location.social_media" :key="social_name">
                        <template v-if="social_link != null">
                            <a class="social-icon" :class="social_name"
                               :href="social_link" target="_blank"></a>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </footer>

    <ModalPopup ref="popup" />
</template>

<script>
import moment from 'moment';
import * as _state from "../lib/state";
import {version} from '../../package'
import * as _transaction from '../lib/transaction';


import ModalPopup from './ModalPopup.vue'

export default {
    name: 'Footer',
    components: {
        ModalPopup
    },
    data() {
        return {
            transactionMethod: '',
            appVersion: version
        }
    },
    mounted () {
        this.transactionMethod = _transaction.getMethod();
    },
    computed: {
        year() {
            return moment().format('Y')
        },

        today() {
            return moment().format('dddd').toLowerCase()
        },

        config () {
            return _state.get('config/getConfig');
        },

        location () {
            return _state.get('location/getLocation');
        },
        has_set_opening_hours() {
            if (this.location && this.location.openingstimes) {
                let noValues = 0;

                Object.values(this.location.openingstimes).forEach(function(elem) {
                    if (elem.trim() === '-') {
                        noValues++;
                    } 
                })

                if (noValues === 7) {
                    return false;
                }
            } 

            return true;
        }
    }
}
</script>
