import { createRouter, createWebHistory } from 'vue-router';

// libs
// todo: move before enter to app.vue -> bootloader?
import * as _state from '../lib/state';
import * as _applicationState from '../lib/application-state';
import * as _transaction from '../lib/transaction';
import * as _location from '../lib/location';
import * as _config from '../lib/config';

// import * as _api from '../lib/api'
import Boot from '../views/Boot.vue';

const routes = [
    {
        path: '/closed',
        name: 'status.closed',
        component: () => import('../views/StatusClosed.vue'),
    },
    {
        path: '/inactive',
        name: 'status.inactive',
        component: () => import('../views/StatusInactive.vue'),
    },
    {
        path: '/',
        name: 'boot',
        component: Boot,
        props: true,

        children: [
            {
                path: '/locations',
                name: 'locations',
                component: () => import('../views/Locations.vue'),
            },
            {
                path: '/landing',
                name: 'landing',
                component: () => import('../views/Landing.vue'),
                beforeEnter(to, from, next) {
                    const overrideUrl = _config.getSettings().homepage_link_override;

                    if (overrideUrl != false) {
                        location.href = overrideUrl;

                        return;
                    }

                    next();
                },
            },
            {
                path: '/:location_slug/aflevermethode',
                alias: '/:location_slug/order/menu',
                name: 'deliverymethod',
                props: true,
                component: () => import('../views/Deliverymethod.vue'),
                // beforeEnter(to, from, next) {
                //     const accessDelivery = _applicationState.canAccessFunction('delivery');
                //     const accessTakeaway = _applicationState.canAccessFunction('takeaway');

                //     if (accessDelivery === true && accessTakeaway === true) {
                //         next();
                //         return;
                //     } else if (accessDelivery === true) {
                //         location.href = '/' + to.params.location_slug + '/bezorgen';
                //     } else if (accessTakeaway === true) {
                //         location.href = '/' + to.params.location_slug + '/afhalen';
                //     } else {
                //         location.href = '/403';
                //     }
                // },
            },
            {
                path: '/:location_slug/menu',
                name: 'menu',
                props: true,
                component: () => import('../views/Menu.vue'),
                beforeEnter(to, from, next) {
                    _applicationState.setHash(to.params.location_slug, 'menu');
                    next();
                },
            },
            {
                path: '/:location_slug/lockers',
                name: 'lockers',
                props: true,
                component: () => import('../views/Lockers.vue'),
                beforeEnter(to, from, next) {
                    _applicationState.setHash(to.params.location_slug, 'lockers');
                    next();
                },
                // beforeEnter(to, from, next) {
                //     const accessLockers = _applicationState.canAccessFunction('elockers');

                //     if (accessLockers === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },

            {
                path: '/:location_slug/bezorgen',
                alias: '/:location_slug/bezorgen/menu',
                name: 'delivery',
                props: true,
                component: () => import('../views/Menu.vue'),
                beforeEnter(to, from, next) {
                    _applicationState.setHash(to.params.location_slug, 'delivery/takeaway');
                    next();
                },
                // beforeEnter(to, from, next) {
                //     // console.log(_transaction.getMethodDelivery());
                //     // const accessDelivery = _applicationState.canAccessFunction('delivery');

                //     // if (accessDelivery === false) {
                //     //     location.href = '/403';
                //     //     return;
                //     // }
                //     next();
                // },
            },
            {
                path: '/:location_slug/afhalen',
                alias: '/:location_slug/afhalen/menu',
                name: 'takeaway',
                props: true,
                component: () => import('../views/Menu.vue'),
                beforeEnter(to, from, next) {
                    _applicationState.setHash(to.params.location_slug, 'delivery/takeaway');
                    next();
                },
                // beforeEnter(to, from, next) {
                //     const accessTakeaway = _applicationState.canAccessFunction('takeaway');

                //     if (accessTakeaway === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },
            {
                path: '/:location_slug/quickorder',
                alias: '/:location_slug/quickorder/menu',
                name: 'quickorder',
                props: true,
                component: () => import('../views/Menu.vue'),
                beforeEnter(to, from, next) {
                    _applicationState.setHash(to.params.location_slug, 'quickorder');
                    next();
                },
                // beforeEnter(to, from, next) {
                //     const accessQuickOrder = _applicationState.canAccessFunction('quickorder');

                //     if (accessQuickOrder === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },
            {
                path: '/:location_slug/reserveren',
                name: 'reservation',
                props: true,
                component: () => import('../views/Reservation.vue'),
                beforeEnter(to, from, next) {
                    _applicationState.setHash(to.params.location_slug, 'reservation');
                    next();
                },
                // beforeEnter(to, from, next) {
                //     const accessReservation = _applicationState.canAccessFunction('reservation');

                //     if (accessReservation === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },
            {
                path: '/:location_slug/aanmelden-bezoekers',
                name: 'visitors',
                props: true,
                component: () => import('../views/VisitorRegister.vue'),
                // beforeEnter(to, from, next) {
                //     const accessVisitors = _applicationState.canAccessFunction('visitors');

                //     if (accessVisitors === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },

            {
                path: '/:location_slug/areas/selection',
                name: 'areaselection',
                props: true,
                component: () => import('../views/table/AreaSelection.vue'),
            },

            {
                path: '/:location_slug/areas/:area_id/selection/table',
                name: 'areatableselection',
                props: true,
                component: () => import('../views/table/AreaTableSelection.vue'),
            },

            {
                path: '/:location_slug/tafel',
                name: 'tableservice',
                props: true,
                component: () => import('../views/Menu.vue'),
                // beforeEnter(to, from, next) {
                //     const accessTables = _applicationState.canAccessFunction('tables');

                //     if (accessTables === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },

            {
                path: '/:location_slug/tafel/afrekenen',
                name: 'checkout.table',
                props: true,
                component: () => import('../views/transaction/CheckoutTable.vue'),
                // beforeEnter(to, from, next) {
                //     const accessTables = _applicationState.canAccessFunction('tables');

                //     if (accessTables === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },

            {
                path: '/:location_slug/tafel/:table_id',
                name: 'table.open',
                props: true,
                component: () => import('../views/TableOpen.vue'),
                // beforeEnter(to, from, next) {
                //     const accessTables = _applicationState.canAccessFunction('tables');

                //     if (accessTables === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },

            {
                path: '/403',
                name: '403',
                props: true,
                component: () => import('../views/403.vue'),
            },

            // table landing
            {
                path: '/tafel/landing',
                name: 'table.landing',
                component: () => import('../views/table/Landing.vue'),
            },

            // no access to table
            {
                path: '/tafel/niet-gevonden',
                name: 'table.404',
                component: () => import('../views/table/404.vue'),
            },

            {
                path: '/:location_slug/winkelwagen',
                name: 'checkout.basket',
                props: true,
                component: () => import('../views/transaction/CheckoutBasket.vue'),
            },

            {
                path: '/:location_slug/bestelling/plaatsen',
                name: 'checkout.transaction',
                props: true,
                component: () => import('../views/transaction/CheckoutTransaction.vue'),
                //  todo
                beforeEnter(to, from, next) {
                    if (_transaction.canOrder()) {
                        next();
                        return;
                    }
                    location.href = '/';
                },
            },

            {
                path: '/:location_slug/bestelling/status-betaling/:payment_id',
                name: 'paymentstatus',
                props: true,
                component: () => import('../views/transaction/PaymentStatus.vue'),
            },
            {
                path: '/:location_slug/bestelling/:transaction_id/bevestiging',
                name: 'confirmation',
                props: true,
                component: () => import('../views/transaction/Confirmation.vue'),
            },

            {
                path: '/:location_slug/quickpickup/:transaction_id',
                name: 'transaction.quickpickup',
                props: true,
                component: () => import('../views/transaction/Quickpickup.vue'),
            },

            // wallet
            {
                path: '/:location_slug/wallet/opwaarderen',
                name: 'checkout.wallet',
                props: true,
                component: () => import('../views/transaction/CheckoutWallet.vue'),
                // beforeEnter(to, from, next) {
                //     const accessWallet = _applicationState.canAccessFunction('wallet');

                //     if (accessWallet === false) {
                //         location.href = '/403';
                //         return;
                //     }
                //     next();
                // },
            },

            {
                path: '/token/activeren',
                name: 'wallettoken.activate',
                component: () => import('../views/WalletTokenActivate.vue'),
            },

            {
                meta: { requiresGuest: true },
                props: true,
                path: '/activate-account',
                name: 'user.activate',
                component: () => import('../views/UserActivate.vue'),
            },

            {
                meta: { requiresAuth: true },
                path: '/my-account/profile',
                name: 'user.myaccount.profile',
                component: () => import('../views/UserProfile.vue'),
            },

            {
                meta: { requiresAuth: true },
                path: '/my-account',
                name: 'user.myaccount',
                component: () => import('../views/UserMyAccount.vue'),
            },

            {
                meta: { requiresAuth: true },
                path: 'my-account/update-password',
                name: 'user.myaccount.update-password',
                component: () => import('../views/UserPasswordUpdate.vue'),
            },
            {
                meta: { requiresAuth: true },
                path: 'my-account/orders',
                name: 'user.myaccount.transactions',
                component: () => import('../views/UserTransactions.vue'),
            },
            {
                meta: { requiresAuth: true },
                path: '/my-orders/:transaction_id',
                name: 'user.transaction',
                props: true,
                component: () => import('../views/UserTransaction.vue'),
            },
            {
                meta: { requiresAuth: true },
                path: '/my-account/wallet',
                name: 'user.myaccount.wallet',
                props: true,
                component: () => import('../views/UserWallet.vue'),
                beforeEnter(to, from, next) {
                    let user = _state.get('user/getUser');

                    if (user.hasWallets === false) {
                        return;
                    }

                    next();
                },
            },
            {
                path: '/:pathMatch(.*)',
                name: '404',
                component: () => import('../views/404.vue'),
            },
        ],
    },
];

const router = createRouter({
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
    history: createWebHistory(),
    routes,
});

// check user is a authenticated
router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        let user = _state.get('user/getUser');

        if (user.authenticated == true) {
            next();

            return;
        }
    }

    if (to.matched.some((record) => record.meta.requiresGuest)) {
        let user = _state.get('user/getUser');

        if (user.authenticated == true) {
            location.href = '/landing';

            return;
        }
    }
    next();
});

// router.beforeEach(async (to) => {
//     //Remove modal-open class for removing scroll disable
//     document.body.classList.remove('modal-open');

//     const restricedRoutesOpenLocation = [
//         'checkout.table',
//         'areaselection',
//         'areatableselection',
//         'tableservice',
//         'table.open',
//         // 'takeaway',
//         'quickorder',
//     ];

//     if ((await _location.isOpen()) === false && restricedRoutesOpenLocation.includes(to.name)) {
//         location.href = '/closed';
//         return;
//     }
// });

// set the application state for each request
router.beforeEach(async (to, from) => {
    await _applicationState.setRoutes(from.name, to.name);
});

router.onError((error) => {
    if (/ChunkLoadError:.*failed./i.test(error.message) || /Loading.*chunk.*failed./i.test(error.message)) {
        window.location.reload();
    }
});

export default router;
