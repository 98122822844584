<template>
    <div>
        <transition name="blur-background">
            <blur v-if="show">
            </blur>
        </transition>

        <transition name="modal-small">
            <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-login fixed-modal-wrapper" v-if="show">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                    <div
                        class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full radius-anchor"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    >
                        <div class="">
                            <div v-if="!isAuthenticationRequired" class="modal-close">
                                <a @click="close()" class="modal-close" aria-label="Close">
                                    <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </a>
                            </div>

                            <div class="">
                                <form class="" @submit.prevent="submit">
                                    <div class="pt-8 pb-3 p-8">
                                        <div class="">
                                            <div
                                                class="text-center sm:text-left py-8 px-4  sm:rounded-sm sm:px-10 pt-0"
                                            >
                                                <h2 class="text-center text-3xl font-extrabold">
                                                    {{ $t('login.title') }}
                                                </h2>
                                                <p class="mt-2 text-center text-sm max-w">
                                                    {{ $t('login.register.or') }}
                                                    <a @click="$emit('open-register');close()"
                                                       class="cursor-pointer font-medium"
                                                    >
                                                        {{ $t('login.register') }}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="flex mb-5 p-3 rounded-sm alert-danger" v-show="invalid">
                                            <div class="flex-grow">{{ $t('login.invalidcredentials') }}</div>
                                        </div>

                                        <div class="">
                                            <div class="mb-3">
                                                <label for="email"
                                                       class="block text-sm font-medium text-gray-700"
                                                >
                                                    {{ $t('fields.email') }}
                                                </label>
                                                <div class="mt-1">
                                                    <input v-model="form.email" @focus="invalid = false"
                                                           id="email"
                                                           name="email" type="email" autocomplete="email"
                                                           required
                                                           class="lowercase appearance-none block w-full px-3 py-2 border rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                                                    >
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label for="password"
                                                       class="block text-sm font-medium text-gray-700"
                                                >
                                                    {{ $t('fields.password') }}
                                                </label>
                                                <div class="mt-1">
                                                    <input v-model="form.password" @focus="invalid = false"
                                                           id="password"
                                                           name="password" type="password"
                                                           autocomplete="current-password"
                                                           required
                                                           class="appearance-none block w-full px-3 py-2 border rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                                                    >
                                                </div>
                                            </div>
                                            <div class="flex items-center justify-end">
                                                <!-- <div class="flex items-center">
                                                    <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                                                    <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                                                        Remember me
                                                    </label>
                                                </div> -->
                                                <div class="text-sm">
                                                    <a @click="$emit('open-passwordforget');close()"
                                                       class="cursor-pointer font-medium"
                                                    >
                                                        {{ $t('login.forgotpassword') }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-4">
                                        <button type="submit"
                                                class="btn btn-primary inline-flex w-full justify-center items-center px-6 py-3 text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                                        >
                                            {{ $t('login.button') }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import * as _auth from '../lib/auth';
import * as _state from '../lib/state';
import * as _applicationState from '../lib/application-state';

import Blur from "./Blur";

export default {
    name: 'Login',
    components: {
        Blur,
    },
    data () {
        return {
            show: false,
            invalid: false,
            form: {
                email: '',
                password: '',
            },
        };
    },
    methods: {
        open () {
            this.reset();
            this.show = true;
        },

        close () {
            this.reset();
            this.show = false;
        },

        reset () {
            this.invalid = false;
            this.form = {
                email: '',
                password: '',
            };
        },

        async submit () {

            let result = await _auth.login(this.form.email, this.form.password);

            if (result === false) {
                this.invalid = true;

                return;
            }

            let user = _state.get('user/getUser');

            if (user.hasAccounts === true) {
                this.$emit('open-accounts');
            }

            this.close();
        },
    },

    computed: {
        isAuthenticationRequired () {
            return _applicationState.isAuthenticationRequired();
        },
    },
};
</script>
