import * as _log from './log';

const logger = _log.get('i18n');

export const getMessages = () => {
    let locales = { en: {}, nl: {}, es: {}, it: {}, de: {}, fr: {} };

    locales['nl'] = require('../locales/nl-NL.json');
    locales['en'] = require('../locales/en-US.json');
    locales['it'] = require('../locales/it-IT.json');
    locales['de'] = require('../locales/de-DE.json');
    locales['fr'] = require('../locales/fr-FR.json');
    locales['es'] = require('../locales/es-ES.json');

    logger.log('translations loaded')();

    return locales;
};

export const getNumberFormats = () => {
    return {
        nl: {
            currency: {
                style: 'currency',
                currency: 'eur',
                notation: 'standard',
            },
            eur: {
                style: 'currency',
                currency: 'eur',
                notation: 'standard',
            },
            usd: {
                style: 'currency',
                currency: 'usd',
                notation: 'standard',
            },
            gbp: {
                style: 'currency',
                currency: 'gbp',
                notation: 'standard',
            },
            chf: {
                style: 'currency',
                currency: 'chf',
                notation: 'standard',
            },
            coins: {
                style: 'decimal',
                notation: 'standard',
            },
            decimal: {
                style: 'decimal',
                minimumSignificantDigits: 3,
                maximumSignificantDigits: 5,
            },
            percentage: {
                style: 'decimal',
                notation: 'standard',
            },
            percent: {
                style: 'percent',
                useGrouping: false,
            },
        },
    };
};
