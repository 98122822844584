<template>
    <ul class="ccss--modal-user-notifications notifications-wrapper absolute z-50 w-fit left-2 right-2 max-w-7xl mx-auto">

        <template v-for="notification in this.notifications" :key="notification.id">
                <ModalUserNotificationMessage v-if="notification"  :notification="notification"/>
        </template>
    </ul>
</template>

<script>
import ModalUserNotificationMessage from './ModalUserNotificationMessage';
import * as _notification from "../lib/notification";

export default {
    name: 'ModalUserNotifications',
    components: {
        ModalUserNotificationMessage
    },
    computed: {
        notifications() {
            return _notification.get();
        },
    },
}
</script>
